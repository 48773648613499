<template>
  <div
    v-if="survey"
  >
    <b-row>
      <b-col
        cols="1"
        xl="1"
        md="1"
      />
      <b-col
        cols="10"
        xl="10"
        md="10"
      >
        <survey-header
          :survey="survey"
          :category="category"
        />
      </b-col>
      <b-col
        cols="1"
        xl="1"
        md="1"
      />
    </b-row>
    <div
      v-for="(surveyQuestion, index) in survey.survey_questions"
      :key="index"
    >
      <!-- Questions -->
      <b-row
        v-if="surveyQuestion.question.question_type === questionType[1]"
      >
        <b-col
          cols="1"
          xl="1"
          md="1"
        />
        <b-col
          cols="10"
          xl="10"
          md="10"
        >
          <survey-open-ended
            :data="surveyQuestion.question"
          />
        </b-col>
        <b-col
          cols="1"
          xl="1"
          md="1"
        />
      </b-row>
      <b-row
        v-if="surveyQuestion.question.question_type === questionType[0]"
      >
        <b-col
          cols="1"
          xl="1"
          md="1"
        />
        <b-col
          cols="10"
          xl="10"
          md="10"
        >

          <template>
            <survey-bar-chart
              :key="index"
              :data="surveyQuestion"
              :index="index"
            />
          </template>

        </b-col>
        <b-col
          cols="1"
          xl="1"
          md="1"
        />
      </b-row>
      <b-row
        v-if="surveyQuestion.question.question_type === questionType[2]"
      >
        <b-col
          cols="1"
          xl="1"
          md="1"
        />
        <b-col
          cols="10"
          xl="10"
          md="10"
        >
          <!-- Check if the question type is 'rating' before rendering survey-chart -->
          <template>
            <survey-pie-chart
              :key="index"
              :data="surveyQuestion"
              :index="index"
            />
          </template>
        </b-col>
        <b-col
          cols="1"
          xl="1"
          md="1"
        />
      </b-row>
    </div>
    <b-row>
      <!-- Footer Card -->
      <b-col
        cols="1"
        xl="1"
        md="1"
      />

      <b-col
        cols="10"
        xl="10"
        md="10"
        class="d-flex justify-content-center"
      >
        <app-footer /></b-col>
      <b-col
        cols="1"
        xl="1"
        md="1"
      />
      <!--/ Footer Card -->
    </b-row>
  </div>
  <div
    v-else
    class="container d-flex justify-content-center align-items-center"
  >
    <b-card class="mb-2">
      <b-card-body>
        <h2 class="mb-2">
          No survey available.
        </h2>
        <p>The Bisevo Team</p>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import AppFooter from '@core/layouts/components/AppFooter.vue'
import constants from '@/constants/static.json'
import mixinAlert from '@/constants/mixinAlert'
import SurveyBarChart from '../../survey/survey-configuration/SurveyBarChart.vue'
import SurveyPieChart from '../../survey/survey-configuration/SurveyPieChart.vue'
import SurveyOpenEnded from '../../survey/survey-configuration/SurveyOpenEnded.vue'
import SurveyHeader from '../../survey/survey-configuration/SurveyHeader.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    SurveyBarChart,
    SurveyPieChart,
    SurveyHeader,
    SurveyOpenEnded,
    AppFooter,
  },

  data() {
    return {
      survey: {
        ID: 0,
        name: '',
        start_date: '',
        end_date: '',
        language: '',
        total_users: 0,
        total_responses: 0,
        average_rating: 0,
        survey_status: '',
        competition_id: 0,
        company_id: 0,
        category_id: 0,
        description: '',
        survey_questions: [],
      },
      category: {},
      surveyID: 0,
      userKey: 0,
      responseKey: 0,
      questionType: constants.QUESTION_TYPE,
    }
  },
  mounted() {
    this.$i18n.locale = constants.LOCALE.SWEDISH
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
    }
  },
  created() {
    let query = ''
    const { id } = this.$route.params
    const { userKey, responseKey } = this.$route.query

    if (id) {
      this.surveyID = id

      if (userKey) {
        this.userKey = userKey
        query = `user_id=${this.userKey}`
      }

      if (responseKey) {
        this.responseKey = responseKey
        query = query ? `${query}&response_id=${this.responseKey}` : `response_id=${this.responseKey}`
      }

      if (this.surveyID !== 0) {
        const payload = {
          id: this.surveyID,
          query,
        }

        this.fetchSurveyByID(payload).then(async response => {
          if (response) {
            this.survey = response.data
            this.$i18n.locale = this.survey.language
            this.survey.description = this.$i18n.t('MESSAGE.SURVEY_SUBMITTED')
          }
        }).catch(error => {
          console.error('Error fetching survey:', error)
        })
      }
    }
  },
  methods: {
    ...mapActions('onboarding', ['fetchSurveyByID']),
  },
}
</script>
